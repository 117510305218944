import { useEffect, useState } from "react";
import styles from "./style.module.css";
import InviteService from "../../services/inviteService";
import IsValidResponse from "../../interfaces/isValidResponse";
import IsComsumedResponse from "../../interfaces/isConsumedResponse";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import { FormatDate } from "../../shared/utils/date_util";

export default function Validate() {
  const location = useLocation();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>("");
  const [path, setPath] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [style, setStyle] = useState<string>("");
  const [isValidResponse, setIsValidResponse] = useState<IsValidResponse>();
  const [isConsumedResponse, setIsConsumedResponse] =
    useState<IsComsumedResponse>();
  const inviteService = new InviteService();
  const [guestName, setGuestName] = useState<string>("");
  const [role, setRole] = useState<string | null>("");

  useEffect(() => {
    if (location.state != null) {
      setPath(location.state.path);
      validateReceivedCode(location.state.code);
    }
  }, []);

  function validateReceivedCode(value: string) {
    setCode(value);
    whichRole(value)
    isActive(value);
  }

  function formatName(name: string): string {
    const lowercaseName = name.toLowerCase();
    const parts = lowercaseName.split(" ");
    const formattedParts = [parts[0], parts[parts.length - 1]].map(
      (part) => part.charAt(0).toUpperCase() + part.slice(1)
    );
    const formattedName = formattedParts.join(" ");
    return formattedName;
  }
  async function whichRole(code: string) {
    const response = await inviteService.searchByInviteCode(code);
    if (typeof response !== "object") {
      setMessage("CÓDIGO INEXISTENTE");
      setStyle("error");
      return;
    }
    setRole(undefined !== response.data?.role ? response.data?.role : "")
  }


  async function isActive(value: string) {
    const response = await inviteService.buscarValidade(value);
    if (typeof response !== "object") {
      setMessage("CÓDIGO INEXISTENTE");
      setStyle("error");
      return;
    }

    if (response?.valid) {
      setIsValidResponse(response as IsValidResponse);
      setGuestName(formatName(response.guest_name));
      setStyle("success");
      return;
    }

    if (response.consumed_at) {
      console.log(response);
      setStyle("warning");
      setGuestName(formatName(response.guest_name));
      setMessage(`Código já utilizado em ${FormatDate(response.consumed_at)}`);
    }

    if (
      response.details &&
      response.details.includes("the given invite is not valid")
    ) {
      setStyle("error");
      setMessage("CÓDIGO INEXISTENTE");
    }

    if (
      response.details &&
      response?.details.includes("incorrect access sector")
    ) {
      const correctSector = response.details.split("try at ")[1];
      setStyle("warning");
      setGuestName(formatName(response.guest_name));
      setMessage(`Setor de acesso incorreto, redirecione para o ${correctSector}`);
    }
  }

  async function consumeCode() {
    const response: IsComsumedResponse = await inviteService.consume(code);

    if (typeof response !== "object") {
      setMessage("Erro na operação");
      setStyle("error");
      return;
    }

    if (response?.consumed) {
      setIsValidResponse(undefined);
      setIsConsumedResponse(response as IsComsumedResponse);
      setStyle("consumed");
      setMessage("Acesso autorizado");
      return;
    }
    setStyle("error");
    setMessage("Erro na operação");
  }

  async function consumeCodeAndPrint() {
    const response: IsComsumedResponse = await inviteService.consume(code);

    if (typeof response !== "object") {
      setMessage("Erro na operação");
      setStyle("error");
      return;
    }

    if (response?.consumed) {
      setIsValidResponse(undefined);
      setIsConsumedResponse(response as IsComsumedResponse);
      setStyle("consumed");
      setMessage("Acesso autorizado");
      printar()
      return;
    }
    setStyle("error");
    setMessage("Erro na operação");
  }

  function reset(): void {
    setCode("");
    setMessage("");
    setIsConsumedResponse(undefined);
    setIsValidResponse(undefined);
    setStyle("");
    navigate(path);
  }

  function renderSuccess() {
    if (!isValidResponse?.valid) {
      return;
    }
    return (
      <div className={`${styles.answer} ${styles.success}`}>
        <p className={styles.title}>{guestName}</p>
        <p className={styles.title}>{role}</p>
        <p className={styles.subtitle}>{code}</p>
      </div>
    );
  }

  function renderWarning() {
    console.log(isValidResponse);
    if (style !== "warning") {
      return;
    }
    return (
      <div className={`${styles.answer} ${styles.warning}`}>
        <p className={styles.title}>{message}</p>
        <p className={styles.subtitle}>{guestName}</p>
        <p className={styles.subtitle}>{code}</p>
      </div>
    );
  }

  function renderFail() {
    if (style !== "error") {
      return;
    }
    return (
      <div className={`${styles.answer} ${styles.error}`}>
        <p className={styles.title}>{message}</p>
      </div>
    );
  }

  function renderConsumed() {
    if (!isConsumedResponse?.consumed) {
      return;
    }
    return (
      <div className={`${styles.answer} ${styles.consumed}`}>
        <p className={styles.title}>{message}</p>
        <p className={styles.subtitle}>{guestName}</p>
      </div>
    );
  }

  function capitalizeFirstLetter(name: string) {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  function printar() {
    const nameParts = guestName.trim().split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
    const userRole = role ? role : '';

    const printWindow = window.open('', '_blank')!;
    printWindow.document.write(`
    <html>

<head>
    <style>
        body {
          font-family: "Arial Black", Arial, sans-serif;
            font-size: 34px;
        }

        h1 {
            text-align: center;
            width: 100%;
            font-size: 34px;
            margin: 0;
            line-height: 35px;
        }

        @page {
            size: auto;
            margin: 0mm;
        }

        .nome {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;  
        }
    </style>
</head>

<body>
    <div class="nome">
    <h1>${capitalizeFirstLetter(firstName)}</h1>
    <h1>${capitalizeFirstLetter(lastName)}</h1>
    <h1 style="font-size: 22px; margin-top: 15px">${capitalizeFirstLetter(userRole)}</h1>
    </div>
</body>

</html>`);
    printWindow.document.close();
    printWindow.print();
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.box}>
          {renderSuccess()}
          {renderWarning()}
          {renderFail()}
          {renderConsumed()}
          {code.length > 0 && (
            <div className={styles.buttons}>
              {isValidResponse?.valid && (
                <button
                  onClick={() => {
                    consumeCode();
                  }}
                >
                  Consumir
                </button>
              )}
              <button
                onClick={() => {
                  reset();
                }}
              >
                Ler Novamente
              </button>
              <button
                onClick={() => {
                  consumeCodeAndPrint();
                }}
              >
                Consumir e Imprimir
              </button>
            </div>
          )}
        </div>
        <button
          className={styles.goback_btn}
          onClick={() => navigate("/painel-operador")}
        >
          Voltar
        </button>
      </div>
      <Footer />
    </>
  );
}
