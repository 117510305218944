/* eslint-disable import/no-anonymous-default-export */
import SelectCompany from "./selectCompany";
import Login from "./login";
import AccessController from "./accessController";
import NotFound from "./notFound";
import GuestSearch from "./guestSearch";
import QRScanner from "./scanner";
import Validate from "./validate";
import SelectEvent from "./selectEvent";
import MonitorPanel from "./monitorPanel";
import AdminPanel from "./adminPanel";
import CustomPrint from "./customPrint";

export default {
  Login,
  PainelOperador: AccessController,
  PainelMonitor: MonitorPanel,
  PainelAdmin: AdminPanel,
  NotFound,
  LeitorDados: GuestSearch,
  QRScanner,
  Validar: Validate,
  SelectEvent,
  SelectCompany,
  CustomPrint,
};
