import { ChangeEvent, useState } from "react";
import styles from "./style.module.css";
import InviteService from "../../../services/inviteService";
import Invite from "../../../interfaces/invite";
import { formatCPF } from "../../utils/str_util";

export default function SearchInvite() {
  const [code, setCode] = useState<string>("");
  const [cpf, setCpf] = useState("");
  const [name, setName] = useState("");
  const [invite, setInvite] = useState<Invite>();
  const [guestList, setGuestList] = useState<Invite[]>([]);
  const inviteService = new InviteService();

  function clearResultState() {
    setInvite(undefined);
    setGuestList([]);
  }

  function clearFieldData() {
    setCode("");
    setCpf("");
    setName("");
  }

  async function searchByCPF(value: string) {
    if (value.length < 11) {
      return;
    }
    clearResultState();
    try {
      const response: Invite = await inviteService.searchByCPF(value);
      setInvite(response);
      clearFieldData();
    } catch {
      const MENSAGEM_ERRO = "Não foi encontrado convite para o CPF: " + value;
      alert(MENSAGEM_ERRO);
    }
  }

  async function searchByName(value: string) {
    if (value.length < 3) {
      return;
    }
    clearResultState();
    try {
      const response: Invite[] = await inviteService.searchByName(value);
      setGuestList(response);
      clearFieldData();
    } catch {
      const MENSAGEM_ERRO =
        "Não foi encontrado convite vinculado ao nome: " + value;
      alert(MENSAGEM_ERRO);
    }
  }

  async function searchByInviteCode(value: string) {
    if (value.length < 8) {
      return;
    }
    clearResultState();
    try {
      const response: Invite = await inviteService.searchByInviteCode(value);
      setInvite(response);
      clearFieldData();
    } catch {
      const MENSAGEM_ERRO =
        "Não foi encontrado nenhum convite com código: " + value;
      alert(MENSAGEM_ERRO);
    }
  }

  const maskCPF = (event: ChangeEvent<HTMLInputElement>) => {
    var value = event.target.value;
    if (value.length > 11) {
      return;
    }
    if (value.length === 11) {
      const cpfMasked = formatCPF(value);
      setCpf(cpfMasked);
      return;
    }
    setCpf(value);
  };

  const formatarData = (data: string) => {
    const dateObject = new Date(data);
    const dataTimezone = dateObject.toLocaleString("pt-br", {
      timeZone: "America/Sao_Paulo",
    });
    const day = dataTimezone.slice(0, 2);
    const month = dataTimezone.slice(3, 5);
    const year = dataTimezone.slice(6, 10);
    const hour = dataTimezone.slice(12, 14);
    const minute = dataTimezone.slice(15, 17);

    return `${day}/${month}/${year} ${hour}:${minute}`;
  };

  const renderResult = (guestList: Invite[], invite?: Invite) => {
    if (guestList.length > 0 && !invite) {
      return (
        <div className={styles.invites_list}>
          <h3>RESULTADO DA PESQUISA</h3>
          {guestList.map((guest, index) => {
            const isConsumed = guest.consumed_at
              ? styles.invite_used
              : styles.invite_available;
            return (
              <div
                onClick={() => setInvite(guest)}
                key={index}
                className={`${styles.invite} ${isConsumed}`}
              >
                <span>{guest.guest_name}</span>
                <span>{guest.guest_email}</span>
                <span>{guest.identificator}</span>
              </div>
            );
          })}
        </div>
      );
    }
    if (invite) {
      return (
        <div className={styles.invite_info}>
          <h3>INFORMAÇÕES DO INGRESSO</h3>
          <div className={styles.invite_present}>
            <p>
              <span>Nome:</span> {invite.guest_name}
            </p>
            <p>
              <span>Documento:</span> {invite.identificator}
            </p>
            <p>
              <span>Código:</span> {invite.guest_code}
            </p>
            <p>
              <span>Consumido:</span>{" "}
              {invite.consumed_at !== undefined
                ? formatarData(invite.consumed_at)
                : "Não consumido"}
            </p>
            <p>
              <span>Setor de acesso:</span> {invite.access_sector}
            </p>
            <p>
              <span>Válido até:</span> {formatarData(invite.valid_until)}
            </p>
            <p>
              <span>Email:</span> {invite.guest_email}
            </p>
            <p>
              <span>Consumido por:</span> {invite.validated_by}
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className={styles.container}>
      <section className={styles.search_invite}>
        <h2>CONSULTAR INGRESSO</h2>
        <div className={styles.form}>
          <label>
            CPF
            <span>
              <input
                type="text"
                inputMode="numeric"
                value={cpf}
                onChange={maskCPF}
                maxLength={14}
                placeholder="000.000.000-00"
              />
              <button onClick={() => searchByCPF(cpf)}>
                <span className={styles.lupa}></span>
              </button>
            </span>
          </label>
          <label>
            Nome
            <span>
              <input
                type="text"
                value={name}
                onChange={(event) => setName(event.target.value.toUpperCase())}
                placeholder="Nome"
              />
              <button onClick={() => searchByName(name)}>
                <span className={styles.lupa}></span>
              </button>
            </span>
          </label>
          <label>
            Código
            <span>
              <input
                type="text"
                value={code}
                maxLength={9}
                onChange={(event) => setCode(event.target.value.toUpperCase())}
                placeholder="XXXXXXXX"
              />
              <button onClick={() => searchByInviteCode(code)}>
                <span className={styles.lupa}></span>
              </button>
            </span>
          </label>
          {renderResult(guestList, invite)}
        </div>
      </section>
    </div>
  );
}
