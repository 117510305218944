import axios from "axios";
import EventResponse from "../interfaces/event";
import EventPayload from "../interfaces/eventPayload";

const URL: string = `${process.env.REACT_APP_URL_API}/event`;

export default class EventService {

  RegisterEvent = async (data: EventPayload): Promise<boolean> => {
    try {
        // const body = {
        //     company_id: data.company_id,
        //     event_name: data.event_name,
        //     date: data.date,
        //     address: data.address,
        //     number_of_guests: data.number_of_guests,
        //     invite_price: data.invite_price,
        //     main_responsible: {
        //         name: data.main_responsible.name,
        //         cpf: data.main_responsible.cpf,
        //         email: data.main_responsible.email,
        //         phone: data.main_responsible.phone,
        //     },
        //     event_type: data.event_type,
        // };

        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };

        const response = await axios.post(URL, data, config);
        return response.status === 201;
    } catch (error: unknown) {
        throw error;
    }
};

  GetEvents = async (): Promise<EventResponse[]> => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const response = await axios.get(`${URL}`, config);
      const eventos: EventResponse[] = response.data;
      eventos.map((evento) => (evento.date = converterData(evento.date)));
      return eventos;
    } catch (error: unknown) {
      throw error;
    }
  };
}

function converterData(dataEvento: string): string {
  const dataObj = new Date(dataEvento);
  const dataComFuso = dataObj.toLocaleString("pt-br", {
    timeZone: "America/Sao_Paulo",
  });
  const dia = dataComFuso.slice(0, 2);
  const mes = dataComFuso.slice(3, 5);
  const ano = dataComFuso.slice(6, 10);
  const hora = dataComFuso.slice(12, 14);
  const minutos = dataComFuso.slice(15, 17);

  return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
}
