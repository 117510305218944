import { ChangeEvent, useState, useEffect } from "react";
import styles from "./style.module.css";
import CompanyService from "../../../services/companyService";
import { formatCPF } from "../../utils/str_util";
import CompanyPayload from "../../../interfaces/companyPayload";


export default function RegisterCompany() {
  const [companyData, setCompanyData] = useState<CompanyPayload>({
    company_name: "",
    fantasy_name: "",
    cnpj: "",
    address: "",
    site: "",
    employees_number: 0,
    activity_sector: "",
    responsibles: [{
      name: "",
      cpf: "",
      email: "",
      phone: ""
    }]
  });

  const companyService = new CompanyService();

  useEffect(() => {

  }, []);

  async function cadastrar() {
    console.log(companyData);

    try {
      const response: boolean = await companyService.RegisterCompany(companyData);
      if (response) {
        alert("Empresa Cadastrada");
      } else {
        alert("Não foi possível cadastrar a empresa!");
      }
    } catch {
      alert("Erro ao cadastrar a empresa!");
    }
  }

  const updateCompanyData = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, key: string) => {
    const { value } = e.target;
    setCompanyData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const maskCPF = (event: ChangeEvent<HTMLInputElement>) => {
    var evento = event

    evento.target.value = evento.target.value.replace(/\D/g, '');
    evento.target.value = evento.target.value.replace(/(\d{3})(\d)/, '$1.$2');
    evento.target.value = evento.target.value.replace(/(\d{3})(\d)/, '$1.$2');
    evento.target.value = evento.target.value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

    return evento;
  };

  const maskCNPJ = (event: ChangeEvent<HTMLInputElement>) => {
    var evento = event
    evento.target.value = evento.target.value.replace(/\D/g, '');
    evento.target.value = evento.target.value.replace(/^(\d{2})(\d)/, '$1.$2');
    evento.target.value = evento.target.value.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
    evento.target.value = evento.target.value.replace(/\.(\d{3})(\d)/, '.$1/$2');
    evento.target.value = evento.target.value.replace(/(\d{4})(\d)/, '$1-$2');
    return evento
  };

  const updateResponsibleData = (e: ChangeEvent<HTMLInputElement>, key: string) => {
    const { value } = e.target;
    setCompanyData(prevState => ({
      ...prevState,
      responsibles: [{
        ...prevState.responsibles[0],
        [key]: value,
      }]
    }));
    console.log(companyData);

  };

  return (
    <div className={styles.container}>
      <section className={styles.box}>
        <h2>CADASTRAR EMPRESA</h2>
        <div className={styles.form}>

          <label>
            Nome
            <span>
              <input
                type="text"
                value={companyData.company_name}
                placeholder="Nome da Empresa"
                onChange={e => updateCompanyData(e, 'company_name')}
              />
            </span>
          </label>

          <label>
            Nome Fantasia
            <span>
              <input
                type="text"
                value={companyData.fantasy_name}
                placeholder="Nome Fantasia"
                onChange={e => updateCompanyData(e, 'fantasy_name')}
              />
            </span>
          </label>

          <label>
            CNPJ
            <span>
              <input
                type="text"
                inputMode="numeric"
                value={companyData.cnpj}
                onChange={e => updateCompanyData(maskCNPJ(e), 'cnpj')}
                placeholder="00.000.000/0000-00"
                maxLength={18}
              />
            </span>
          </label>

          <label>
            Endereço
            <span>
              <input
                type="text"
                value={companyData.address}
                placeholder="Endereço"
                onChange={e => updateCompanyData(e, 'address')}
              />
            </span>
          </label>

          <label>
            Site
            <span>
              <input
                type="text"
                value={companyData.site}
                placeholder="Site"
                onChange={e => updateCompanyData(e, 'site')}
              />
            </span>
          </label>

          <label>
            Numero de colaboradores
            <span>
              <input
                type="number"
                value={companyData.employees_number}
                onChange={e => updateCompanyData(e, 'employees_number')}
              />
            </span>
          </label>

          <label>
            Tipo de evento
            <span>
              <input
                type="text"
                value={companyData.activity_sector}
                placeholder="Confraternização"
                onChange={e => updateCompanyData(e, 'activity_sector')}
              />
            </span>
          </label>

          <div>
            <div className={styles.input_responsavel_box}>
              <label>Responsável</label>
              <span>
                <input
                  type="text"
                  value={companyData.responsibles[0].name}
                  placeholder="Nome"
                  onChange={e => updateResponsibleData(e, 'name')}
                />
              </span>

              <span>
                <input
                  type="email"
                  value={companyData.responsibles[0].email}
                  placeholder="email@example.com"
                  onChange={e => updateResponsibleData(e, 'email')}
                />
              </span>

              <span>
                <input
                  type="text"
                  value={companyData.responsibles[0].cpf}
                  placeholder="000.000.000-00"
                  maxLength={14}
                  onChange={e => updateResponsibleData(maskCPF(e), 'cpf')}
                />
              </span>

              <span>
                <input
                  type="text"
                  value={companyData.responsibles[0].phone}
                  placeholder="(71) 99999-9999"
                  maxLength={11}
                  onChange={e => updateResponsibleData(e, 'phone')}
                />
              </span>
            </div>

            <button onClick={() => cadastrar()}>CADASTRAR</button>
          </div>
        </div>
      </section>
    </div>
  );
}
