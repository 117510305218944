import styles from "./dashboardStyle.module.css";
import { useState, useEffect } from "react";
import InviteService from "../../../services/inviteService";
import Parcial from "../../../interfaces/parcial";

export default function Dashboard() {
    const inviteService = new InviteService();


    const [dados, setDados] = useState<Parcial>({
        accessedTotal: 0,
        guestsTotal: 0,
        totalHora: 0,
        accessBySector: [
            {
                sector: "Pesquisando",
                quantity: 0
            }
        ],
        accessByRole: [
            {
                role: "Pesquisando",
                quantity: 0
            }
        ],
    });

    useEffect(() => {
        buscarDados();
    }, []);

    const buscarDados = async () => {
        setDados(await inviteService.getAllInvites());
    }

    const formatarHorario = () => {
        const horaAtual = new Date();
        const horaUmaHoraAtras = new Date(horaAtual.getTime() - 60 * 60 * 1000);
        const horaFormatada = horaUmaHoraAtras.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        const horaAtualFormatada = horaAtual.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        return ` acessos das ${horaFormatada} às ${horaAtualFormatada}`;
    };

    return (
        <div className={styles.contagem}>
            <h1>Contagem de acessos</h1>
            <div className={styles.contagem_total}>
                <h2>Total</h2>
                <h3>{dados.accessedTotal} / {dados.guestsTotal}</h3>
            </div>
            <div className={styles.contagem_hora}>
                <h2>Última hora</h2>
                <h3>{dados.totalHora}  {formatarHorario()}</h3>
            </div>
            <div className={styles.contagem_categoria}>
                <h2>Acessos por Categoria</h2>
                {dados.accessByRole.map((item, index) => (
                    <div key={index}>
                        <h3>{item.role}: {item.quantity}</h3>
                    </div>
                ))}
            </div>
            <div className={styles.contagem_setor}>
                <h2>Acessos por Setor</h2>
                {dados.accessBySector.map((item, index) => (
                    <div key={index}>
                        <h3>{item.sector}: {item.quantity}</h3>
                    </div>
                ))}
            </div>
        </div>
    );
}
