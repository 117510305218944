import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import styles from "./style.module.css";
import { Link } from "react-router-dom";

export default function AccessController() {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={`${styles.boxes}`}>
          <Link to="/leitor-dados">
            <div>
              <img src="./guest.png" alt="Imagem de um documento" />
              <button>Pesquisar Convidado</button>
            </div>
          </Link>
        </div>
        <div className={`${styles.boxes}`}>
          <Link to="/impressao-customizada">
            <div>
              <img src="./print.png" alt="Imagem de uma impressora" />
              <button>Impressão customizada</button>
            </div>
          </Link>
        </div>
        <div className={`${styles.boxes}`}>
          <Link to="/leitor-code">
            <div>
              <img src="./qr-code.png" alt="Imagem de um QRcode" />
              <button>Scanear QRCode</button>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}
