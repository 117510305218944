const formatCPF = (cpf: string): string => {
  const digits = cpf.replace(/\D/g, "").split("");
  const p1 = digits.slice(0, 3).join("");
  const p2 = digits.slice(3, 6).join("");
  const p3 = digits.slice(6, 9).join("");
  const p4 = digits.slice(9).join("");
  const formattedCPF = `${p1}.${p2}.${p3}-${p4}`;
  return formattedCPF;
};

const formatCNPJ = (cnpj: string): string => {
  const digits = cnpj.replace(/\D/g, "").split("");
  const p1 = digits.slice(0, 2).join("");
  const p2 = digits.slice(2, 5).join("");
  const p3 = digits.slice(5, 8).join("");
  const p4 = digits.slice(8, 12).join("");
  const p5 = digits.slice(12).join("");
  const formattedCNPJ = `${p1}.${p2}.${p3}/${p4}-${p5}`;
  return formattedCNPJ;
};


export { formatCPF, formatCNPJ };
