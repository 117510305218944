import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import { useState } from "react";
import styles from "./style.module.css";
import RegisterCompany from "../../shared/components/registerCompany";
import RegisterEvent from "../../shared/components/registerEvent";
import RegisterGuests from "../../shared/components/registerGuests";

export default function AdminPanel() {
  const [showComponent, setShowComponent] = useState<string>("event");
  const [visibilidadeNav, setVisibilidadeNav] = useState<boolean>(true);

  const components: { [key: string]: JSX.Element } = {
    company: <RegisterCompany />,
    event: <RegisterEvent />,
    guest: <RegisterGuests />,
  };

  function presentComponent() {
    return components[showComponent];
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        <button
          className={styles.toggle_visibility}
          onClick={() => setVisibilidadeNav(!visibilidadeNav)}
        >
          ↩
        </button>
        {visibilidadeNav && (
          <nav className={styles.nav}>
            <ul>
              <li onClick={() => setShowComponent("company")}>
                CADASTRAR EMPRESA
              </li>
              <li onClick={() => setShowComponent("event")}>
                CADASTRAR EVENTO
              </li>
              <li onClick={() => setShowComponent("guest")}>
                CADASTRAR INGRESSO
              </li>
            </ul>
          </nav>
        )}
        <div className={styles.box}>{presentComponent()}</div>
      </div>
      <Footer />
    </>
  );
}
