import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import LoginData from "../../interfaces/loginData";
import styles from "./style.module.css";
import React, { useEffect, useState } from "react";
import AuthService from "../../services/autheticationService";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [loginData, setLoginData] = useState<LoginData>({
    user: "",
    password: "",
  });
  const [handleVisibilidadeSenha, setHandleVisibilidadeSenha] =
    useState<boolean>(false);
  const [erroLogin, setErroLogin] = useState<boolean>(false);
  const navigate = useNavigate();
  const authService = new AuthService();

  useEffect(() => {
    const usuarioAutenticado = authService.isUserAuthenticated();
    navigate("/")
    if (usuarioAutenticado) {
      completarLogin();
    }
    authService.logout();
  }, []);

  const handleLoginSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    logar(loginData);
  };

  async function logar(loginData: LoginData) {
    if (await authService.login(loginData)) {
      completarLogin();
    } else {
      setErroLogin(true);
    }
  }

  function completarLogin() {
    console.log(authService.whichIsMainRole() === "admin");

    if (authService.whichIsMainRole() === "admin") {
      navigate("/painel-admin", { replace: true });
    } else {
      navigate("/selecionar-organizacao", { replace: true });
    }
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <form onSubmit={handleLoginSubmit} className={styles.form}>
            <h2>LOGIN</h2>
            <div>
              <label>USUÁRIO</label>
              <input
                type="text"
                required
                name="username"
                value={loginData.user}
                autoComplete="on"
                onChange={(e) =>
                  setLoginData({ ...loginData, user: e.target.value })
                }
              />
            </div>

            <div className={styles.password}>
              <label>SENHA</label>
              <div>
                <input
                  required
                  name="password"
                  type={handleVisibilidadeSenha ? "text" : "password"}
                  value={loginData.password}
                  autoComplete="on"
                  onChange={(e) =>
                    setLoginData({ ...loginData, password: e.target.value })
                  }
                />
                <span
                  onClick={() =>
                    setHandleVisibilidadeSenha(!handleVisibilidadeSenha)
                  }
                  className={
                    handleVisibilidadeSenha ? "fa fa-eye" : "fa fa-eye-slash"
                  }
                ></span>
              </div>
            </div>

            {erroLogin && (
              <div className={styles.mensagem_erro}>
                <h2>
                  Erro ao tentar realizar login
                </h2>
              </div>
            )}
            <button type="submit">ACESSAR</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
