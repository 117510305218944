import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import styles from "./style.module.css";
import {useNavigate} from "react-router-dom";
import {useState, useEffect} from "react";
import EventResponse from "../../interfaces/event";
import {PanelAccessRoles} from "../../shared/constants";
import EventService from "../../services/eventService";

export default function SelectEvent() {
    const navigate = useNavigate();
    const eventService = new EventService();
    const [events, setEvents] = useState<EventResponse[]>([]);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [currentEvent, setCurrentEvent] = useState<EventResponse>();
    const [sectors, setSectors] = useState<string[]>([]);
    const [currentSector, setCurrentSector] = useState<string>("");

    useEffect(() => {
        const roles = localStorage.getItem("roles") || "";
        setUserRoles(roles.split(","));
        getEvents();
    }, []);

    async function getEvents() {
        const events = await eventService.GetEvents();
        const company = localStorage.getItem("id_company") || "";
        if (company != "") {
            const filteredEventsByCompany = events.filter(event => event.company_id === company);
            setEvents(filteredEventsByCompany);
        } else {
            setEvents(events);
        }
    }

    function selectEvent(event: EventResponse) {
        const currentSectorsAvailable = event.access_sectors || ["PRINCIPAL"];
        setCurrentEvent(event);
        setSectors(currentSectorsAvailable);
        setCurrentSector(currentSectorsAvailable[0]);
    }

    function isPanelController(): boolean {
        return userRoles.some((role) =>
            PanelAccessRoles.some((panelRole) => panelRole === role)
        );
    }

    function startSession() {
        const eventID = currentEvent?.id || "";
        const eventName = currentEvent?.event_name || "";
        localStorage.setItem("event_id", eventID);
        localStorage.setItem("event_name", eventName);
        localStorage.setItem("access_sector", currentSector);
        localStorage.setItem("event_access_sectors", sectors.join(","));

        if (isPanelController()) {
            return navigate("/painel-monitor");
        }
        return navigate("/painel-operador");
    }

    return (
        <>
            <Header/>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.access_info}>
                        {!isPanelController() && <h2>SETOR OPERACIONAL</h2>}
                        <div>
                            {!isPanelController() && (
                                <select
                                    value={currentSector}
                                    disabled={currentEvent === undefined}
                                    onChange={(event) => setCurrentSector(event.target.value)}
                                >
                                    {sectors.map((acesso, index) => (
                                        <option key={index} value={acesso}>
                                            {acesso}
                                        </option>
                                    ))}
                                </select>
                            )}
                            <button
                                disabled={currentEvent === undefined}
                                className={`${
                                    currentEvent?.id
                                        ? styles.button_enable
                                        : styles.button_desable
                                }`}
                                onClick={() => startSession()}
                                type="button"
                            >
                                INICIAR
                            </button>
                        </div>
                    </div>

                    <h2 className={styles.event_list}>SELECIONE UM EVENTO</h2>

                    <div className={styles.events}>
                        {events.map((event, index) => (
                            <div
                                key={index}
                                onClick={() => selectEvent(event)}
                                className={
                                    currentEvent?.id === event.id
                                        ? styles.event_selected
                                        : styles.event
                                }
                            >
                                <p>{event.event_name}</p>
                                <p>{event.date}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
}
