import styles from "./style.module.css";
import AuthService from "../../../services/autheticationService";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function Header() {
    const authService = new AuthService();
    const user = localStorage.getItem("operador");
    const eventName = localStorage.getItem("event_name");
    const [logado, setLogado] = useState<boolean>(false);

    useEffect(() => {
        setLogado(authService.isUserAuthenticated());
    }, []);

    function logout() {
        setLogado(false);
        authService.logout();
    }

    return (
        <header className={styles.header}>
            <nav>
                <img src="./Logo_UnirseBR_branco.png" alt="logo horizontal unirse br"/>
                {logado && (

                    <>
                        <div className={styles.info}>
                            <p>Usuario: {user}</p>
                            <p>Evento: {eventName}</p>
                        </div>
                        <Link onClick={() => logout()} to="/">
                            <p>Sair</p>
                            <img src="./logout.png" alt="Icone de logout"/>
                        </Link>
                    </>
                )}
            </nav>
        </header>
    );
}
