import AuthService from "../services/autheticationService";
import Pages from "../pages";

const authenticationService = new AuthService();

const ProtectedRoutes = ({ children, value }: any) => {
  if (!authenticationService.isUserAuthenticated()) {
    return <Pages.Login />;
  }

  if (!hasEventSelected() && isProtectedRoute(value) ) {
    return <Pages.SelectCompany />;
  }

  return children;
};

function isProtectedRoute(value: string){
  if(value === "selecionar-empresa" || value === "selecionar-evento" || value === "painel-admin"){
    return false;
  }
  return true;
}

function hasEventSelected(): boolean {
  return localStorage.getItem("event_id") !== null;
}

export default ProtectedRoutes;
