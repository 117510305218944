import {ChangeEvent, useState, useEffect} from "react";
import styles from "./style.module.css";
import InviteService, {UserData} from "../../../services/inviteService";
import Invite from "../../../interfaces/invite";
import {formatCPF} from "../../utils/str_util";
import {FormatDate} from "../../utils/date_util";

export default function RegisterInvite() {
    const [eventAccessSectors, setEventAccessSectors] = useState<string[]>([
        "PRINCIPAL",
    ]);
    const [userData, setUserData] = useState<UserData>({} as UserData);
    const [ingresso, setIngresso] = useState<Invite>();
    const [role, setRole] = useState<string>("")
    const inviteService = new InviteService();

    useEffect(() => {
        const availableSectors = localStorage.getItem("event_access_sectors");
        setEventAccessSectors(availableSectors?.split(",") || []);
    }, []);

    async function cadastrar() {
        try {
            var data = userData;
            data.guest_email = data.guest_email ?? "caturebadev@gmail.com"
            data.identificator = data.identificator === undefined ? "00000000000" : userData.identificator;
            data.data = data.data ? {role: role} : {role: "Sem Cadastro"};

            const response: Invite = await inviteService.cadastrar(data);
            setIngresso(response);
        } catch {
            alert("Erro ao cadastrar Usuario!");
        }
    }

    const updateUserData = (dado: { [key: string]: string }) => {
        userData.data = role == "" ? null : {role: role}
        const inviteData: UserData = {
            guest_name: dado["name"] ?? userData.guest_name,
            guest_email: dado["email"] ?? userData.guest_email,
            identificator: dado["cpf"] ?? userData.identificator,
            identificator_type: "CPF",
            access_sector: dado["sector"] ?? userData.access_sector ?? "PRINCIPAL",
            data: dado["data"] ?? userData.data
        };
        setUserData(inviteData);
    };

    const maskCPF = (event: ChangeEvent<HTMLInputElement>) => {
        const valueLength = event.target.value.length;
        if (valueLength > 11) {
            return;
        }
        if (valueLength === 11) {
            updateUserData({cpf: formatCPF(event.target.value)});
            return;
        }
        updateUserData({cpf: event.target.value});
    };

    const renderResult = (invite?: Invite) => {
        if (invite) {
            return (
                <div className={styles.invite_info}>
                    <h3>INFORMAÇÕES DO INGRESSO CADASTRADO</h3>
                    <div className={styles.invite}>
                        <p>
                            <span>Nome:</span> {invite.guest_name}
                        </p>
                        <p>
                            <span>Documento:</span> {invite.identificator}
                        </p>
                        <p>
                            <span>Código:</span> {invite.guest_code}
                        </p>
                        <p>
                            <span>Consumido:</span>{" "}
                            {invite.consumed_at !== undefined
                                ? FormatDate(invite.consumed_at)
                                : "Não consumido"}
                        </p>
                        <p>
                            <span>Setor de acesso:</span> {invite.access_sector}
                        </p>
                        <p>
                            <span>Válido até:</span> {FormatDate(invite.valid_until)}
                        </p>
                        <p>
                            <span>Email:</span> {invite.guest_email}
                        </p>
                        <p>
                            <span>Consumido por:</span> {invite.validated_by}
                        </p>
                    </div>
                </div>
            );
        }
    };

    return (
        <div className={styles.container}>
            <section className={styles.box}>
                <h2>CADASTRAR INGRESSO</h2>
                <div className={styles.form}>
                    <label>
                        CPF
                        <span>
              <input
                  type="text"
                  id="cpf"
                  inputMode="numeric"
                  value={userData.identificator}
                  onChange={maskCPF}
                  placeholder="000.000.000-00"
                  maxLength={14}
              />
            </span>
                    </label>
                    <label>
                        Nome
                        <span>
              <input
                  type="text"
                  id="name"
                  value={userData.guest_name}
                  placeholder="Nome Sobrenome"
                  onChange={(event) =>
                      updateUserData({name: event.target.value})
                  }
              />
            </span>
                    </label>
                    <label>
                        Modalidade de participação
                        <span>
              <input
                  type="text"
                  id="role"
                  value={role}
                  placeholder="Modalidade de participação"
                  onChange={(event) => setRole(event.target.value)}
              />
            </span>
                    </label>
                    <label>
                        Email
                        <span>
              <input
                  type="text"
                  id="email"
                  value={userData.guest_email}
                  placeholder="email@example.com"
                  onChange={(event) =>
                      updateUserData({email: event.target.value.toLowerCase()})
                  }
              />
            </span>
                    </label>
                    <div>
                        <label>
                            Setor
                            <span>
                <select
                    value={userData.access_sector}
                    onChange={(event) =>
                        updateUserData({sector: event.target.value})
                    }
                >
                  {eventAccessSectors.map((sector, index) => (
                      <option key={index} value={sector}>
                          {sector}
                      </option>
                  ))}
                </select>
              </span>
                        </label>
                        <button onClick={() => cadastrar()}>CADASTRAR</button>
                    </div>
                </div>
                {renderResult(ingresso)}
            </section>
        </div>
    );
}
