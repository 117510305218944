import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Company from "../../interfaces/company";
import CompanyService from "../../services/companyService";

export default function SelectCompany() {
  const navigate = useNavigate();
  const [companies, setCompanies] = useState<Company[]>([]);
  const companyService = new CompanyService()

  useEffect(() => {
    searchCompanies();
  }, []);

  async function searchCompanies() {
    setCompanies(await companyService.GetCompanies());
  }

  function selectCompany(companyID: string): void {
    localStorage.setItem("id_company", companyID);
    return navigate("/selecionar-evento");
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.box}>
          <h2>SELECIONE UMA ORGANIZAÇÃO</h2>
          <div className={styles.companies}>
            {companies.map((company) => (
              <div
                key={company.id}
                onClick={() => selectCompany(company.id)}
                className={styles.company}
              >
                <p>{company.company_name}</p>
                <p>{company.cnpj}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
