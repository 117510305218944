import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import {ChangeEvent, useState} from "react";
import styles from "./style.module.css";
import InviteService from "../../services/inviteService";
import {useNavigate} from "react-router-dom";
import Invite from "../../interfaces/invite";
import {formatCPF} from "../../shared/utils/str_util";

export default function GuestSearch() {
    const navigate = useNavigate();
    const [notFound, setNotFound] = useState<boolean>(false);
    const [cpf, setCpf] = useState("");
    const [nome, setNome] = useState("");
    const [listaNomes, setListaNomes] = useState<Invite[]>([]);
    const ingressoService = new InviteService();

    async function buscarPorCPF(value: string) {
        if (value.length !== 14) {
            return
        }
        try {
            const response: Invite = await ingressoService.searchByCPF(value);
            if (response === undefined) {
                const MENSAGEM_ERRO =
                    "Não foi encontrado convite vinculado ao CPF: " + value;
                console.log(MENSAGEM_ERRO);
                alert(MENSAGEM_ERRO);
                return;
            }
            redirecionarComProps(response.guest_code);
        } catch (error) {
            setNotFound(true)
        }
    }

    async function buscarPorNome(value: string) {
        setListaNomes([])
        try {
            const response: Invite[] = await ingressoService.searchByName(value);
            console.log(typeof response);

            if (typeof response === "string") {
                const MENSAGEM_ERRO =
                    "Não foi encontrado nenhum convite para: " + value;
                console.log(MENSAGEM_ERRO);
                alert(MENSAGEM_ERRO);
                return;
            }
            setListaNomes(response);
        } catch {
            setNotFound(true)
        }
    }

    function redirecionarComProps(code: string) {
        navigate("/validar", {state: {code: code, path: "/leitor-dados"}});
    }

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            buscarPorNome(nome);
        }
    };

    const maskCPF = (event: ChangeEvent<HTMLInputElement>) => {
        var value = event.target.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
        setCpf(value)
    };

    const renderResult = (guestList: Invite[]) => {
        if (guestList.length > 0) {
            var activeResults = guestList.filter((guest) => guest.consumed_at === undefined);
            var inactiveResults = guestList.filter((guest) => guest.consumed_at !== undefined);
            return (
                <div className={styles.showing_results}>
                    <h2>RESULTADO DA PESQUISA</h2>
                    <div className={styles.invites_list}>
                        {activeResults.map((convidado, index) => (
                            <div
                                onClick={() => {
                                    if (convidado.consumed_at === undefined)
                                        redirecionarComProps(convidado.guest_code);
                                }}
                                key={index}
                                className={`${styles.invite} ${convidado.consumed_at !== undefined
                                    ? styles.invite_used
                                    : styles.invite_available
                                } `}
                            >
                                <p>{convidado.guest_name}</p>
                                <p>
                                    {convidado.identificator === "00000000000"
                                        ? "CPF Não Cadastrado"
                                        : formatCPF(convidado.identificator)}
                                </p>
                            </div>
                        ))}

                        {inactiveResults.map((convidado, index) => (
                            <div
                                onClick={() => {
                                    if (convidado.consumed_at === undefined)
                                        redirecionarComProps(convidado.guest_code);
                                }}
                                key={index}
                                className={`${styles.invite} ${convidado.consumed_at !== undefined
                                    ? styles.invite_used
                                    : styles.invite_available
                                } `}
                            >
                                <p>{convidado.guest_name}</p>
                                <p>
                                    {convidado.identificator === "00000000000"
                                        ? "CPF Não Cadastrado"
                                        : formatCPF(convidado.identificator)}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <Header/>
            <div className={styles.container}>
                <div className={styles.box}>
                    <div className={styles.form}>
                        <h2>BUSCA DE CONVIDADO</h2>
                        {listaNomes.length == 0 && notFound && (
                            <div className={styles.not_found_message}><h3>Erro ao Buscar Usuario</h3></div>
                        )}
                        <label>
                            CPF
                            <span>
                <input
                    type="text"
                    inputMode="numeric"
                    value={cpf}
                    onChange={maskCPF}
                    maxLength={14}
                    placeholder="000.000.000-00"
                />
                <button
                    disabled={cpf.length < 11}
                    onClick={() => buscarPorCPF(cpf)}
                >
                  <span className={styles.lupa}></span>
                </button>
              </span>
                        </label>
                        <label>
                            Nome
                            <span>
                <input
                    type="text"
                    value={nome}
                    onKeyPress={handleKeyPress}
                    placeholder="NOME"
                    onChange={(event) =>
                        setNome(event.target.value.toUpperCase())
                    }
                />
                <button
                    onClick={() => buscarPorNome(nome)}
                >
                  <span className={styles.lupa}></span>
                </button>
              </span>
                        </label>
                    </div>
                    {renderResult(listaNomes)}
                </div>
                <button
                    className={styles.goback_btn}
                    onClick={() => navigate("/painel-operador")}
                >
                    Voltar
                </button>
            </div>
            <Footer/>
        </>
    );
}
