import { ChangeEvent, useState, useEffect } from "react";
import styles from "./style.module.css";
import EventService from "../../../services/eventService";
import CompanyService from "../../../services/companyService"
import Invite from "../../../interfaces/invite";
import { formatCPF } from "../../utils/str_util";
import { FormatDate } from "../../utils/date_util";
import EventPayload from "../../../interfaces/eventPayload";
import EventResponse from "../../../interfaces/event";
import Company from "../../../interfaces/company";

export default function RegisterEvent() {
  const [eventRegister, setEventRegister] = useState<EventPayload>()
  const [companies, setCompanies] = useState<Company[]>()
  const eventService = new EventService();
  const companyService = new CompanyService();
  const [companyId, setCompanyId] = useState<string>("");
  const [eventName, setEventName] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [numberOfGuests, setNumberOfGuests] = useState<number>(0);
  const [invitePrice, setInvitePrice] = useState<number>(0);
  const [eventType, setEventType] = useState<string>("");
  const [responsableName, setResponsableName] = useState<string>("");
  const [responsableEmail, setResponsableEmail] = useState<string>("");
  const [responsableCpf, setResponsableCpf] = useState<string>("");
  const [responsablePhone, setResponsablePhone] = useState<string>("");
  const [mainResponsible, setMainResponsible] = useState<{
    name: string;
    cpf: string;
    email: string;
    phone: string;
  }>({
    name: "",
    cpf: "",
    email: "",
    phone: ""
  });


  useEffect(() => {
    findCompanies()
  }, [])

  async function findCompanies() {
    setCompanies(await companyService.GetCompanies())
  }

  async function cadastrar() {
    try {
      if (eventRegister !== undefined && await eventService.RegisterEvent(eventRegister)) {
        alert("Evento cadastrado com sucesso!")
      }
    } catch {
      alert("Erro ao cadastrar Evento!");
    }
  }

  function envioForm(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const payload: EventPayload = {
      company_id: companyId,
      event_name: eventName,
      date: date + "T00:00:00.000000000Z",
      address: address,
      number_of_guests: numberOfGuests,
      invite_price: invitePrice,
      main_responsible: {
        name: responsableName,
        cpf: responsableCpf,
        email: responsableEmail,
        phone: responsablePhone
      },
      event_type: eventType
    };
    console.log(payload);
    setEventRegister(payload)
    cadastrar()
  }

  // const updateUserData = (data: { [key: string]: string }) => {
  //   const inviteData = {
  //     guest_name: data["name"] ?? userData.guest_name,
  //     guest_email: data["email"] ?? userData.guest_email,
  //     identificator: data["cpf"] ?? userData.identificator,
  //     identificator_type: "CPF",
  //     access_sector: data["sector"] ?? userData.access_sector ?? "PRINCIPAL",
  //   };
  //   setUserData(inviteData);
  // };

  // const maskCPF = (event: ChangeEvent<HTMLInputElement>) => {
  //   const valueLength = event.target.value.length;
  //   if (valueLength > 11) {
  //     return;
  //   }
  //   if (valueLength === 11) {
  //     updateUserData({ cpf: formatCPF(event.target.value) });
  //     return;
  //   }
  //   updateUserData({ cpf: event.target.value });
  // };


  return (
    <div className={styles.container}>

      <section className={styles.box}>
        <h2>CADASTRAR EVENTO</h2>
        <form className={styles.form} onSubmit={envioForm}>
          <div className={styles.input_box}>
            <select value={companyId} onChange={(event) => setCompanyId(event.target.value)}>
              {companies?.map((company, index) => (
                <option key={index} value={company.id}>
                  {company.company_name}
                </option>
              ))}
            </select>
          </div>

          <div className={styles.input_box}>
            <label>Nome do evento</label>
            <input
              type="text"
              onChange={(event) =>
                setEventName(event.target.value)}
            />
          </div>

          <div className={styles.input_box}>
            <label>Data inicio</label>
            <input
              type="date"
              onChange={(event) =>
                setDate(event.target.value)}
            />
          </div>

          {/* <div className={styles.input_box}>
            <label>Data termino</label>
            <input
              type="date"
              onChange={(event)=> 
                setEventName(event.target.value)}
            />
          </div> */}

          <div className={styles.input_box}>
            <label>Endereço</label>
            <input
              type="text"
              onChange={(event) =>
                setAddress(event.target.value)}
            />
          </div>

          <div className={styles.input_box}>
            <label>Estimativa de convidados</label>
            <input
              type="number"
              onChange={(event) =>
                setNumberOfGuests(Number(event.target.value))}
            />
          </div>

          <div className={styles.input_box}>
            <label>Valor do do convite (Opicional)</label>
            <input
              type="number"
              onChange={(event) =>
                setInvitePrice(Number(event.target.value))}
            />
          </div>

          <div className={styles.input_box}>
            <label>Tipo de evento</label>
            <input
              type="text"
              onChange={(event)=> 
                setEventType(event.target.value)}
            />
          </div>

          <div className={styles.responsavel}>
            <h3>Responsável</h3>
            <div className={styles.input_box}>
              <label>Nome</label>
              <input
                type="text"
                onChange={(event) =>
                  setResponsableName(event.target.value)}
              />
            </div>

            <div className={styles.input_box}>
              <label>Email</label>
              <input
                type="email"
                onChange={(event) =>
                  setResponsableEmail(event.target.value)}
              />
            </div>

            <div className={styles.input_box}>
              <label>CPF</label>
              <input
                type="text"
                maxLength={14}
                onChange={(event) =>
                  setResponsableCpf(event.target.value)}
              />
            </div>

            <div className={styles.input_box}>
              <label>Telefone</label>
              <input
                type="text"
                maxLength={11}
                onChange={(event) =>
                  setResponsablePhone(event.target.value)}
              />
            </div>
          </div>


          <button type="submit">Cadastrar</button>
        </form>
      </section>
    </div>
  );
}
