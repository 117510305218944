import axios from "axios";
import Company from "../interfaces/company";
import CompanyPayload from "../interfaces/companyPayload";

const URL: string = `${process.env.REACT_APP_URL_API}/company`;

export default class CompanyService {
  axiosInstance = axios.create({
    baseURL: URL,
  });

  GetCompanies = async (): Promise<Company[]> => {
    try {
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const response = await axios.get(URL, config);
      const companies: Company[] = response.data;
      companies.map((company) => (company.created_at = converterData(company.created_at)));
      companies.map((company) => (company.updated_at = converterData(company.updated_at)));
      return companies;
    } catch (error: unknown) {
      throw error;
    }
  };

  RegisterCompany = async (data: CompanyPayload): Promise<boolean> => {
    try {
      const body = {
        company_name: data.company_name,
        fantasy_name: data.fantasy_name,
        cnpj: data.cnpj,
        address: data.address,
        site: data.site,
        employees_number: Number(data.employees_number),
        activity_sector: data.activity_sector,
        responsibles: [{
          name: data.responsibles[0].name,
          cpf: data.responsibles[0].cpf,
          email: data.responsibles[0].email,
          phone: data.responsibles[0].phone,
        }],
      };

      console.log(body);
      
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      };
      const response = await axios.post(URL, body, config);
      return response.status === 201;
    } catch (error: unknown) {
      throw error;
    }
  };
}

function converterData(dataEvento: string): string {
  const dataObj = new Date(dataEvento);
  const dataComFuso = dataObj.toLocaleString("pt-br", {
    timeZone: "America/Sao_Paulo",
  });
  const dia = dataComFuso.slice(0, 2);
  const mes = dataComFuso.slice(3, 5);
  const ano = dataComFuso.slice(6, 10);
  const hora = dataComFuso.slice(12, 14);
  const minutos = dataComFuso.slice(15, 17);

  return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
}
