import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import styles from "./notFoundStyle.module.css";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.box}>
          <h1>Algo deu errado!</h1>
          <div className={styles.box_botao}>
            <Link className={styles.botao} to="/">
              Retorne ao inicio
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
