import {Route, Routes} from "react-router-dom";
import styles from "./AppRoutes.module.css";
import ProtectedRoutes from "./ProtectedRoutes";
import Pages from "../pages";

export default function Router() {
    return (
        <div className={styles.container}>
            <Routes>
                <Route path="" element={<Pages.Login/>}/>
                <Route path="*" element={<Pages.NotFound/>}/>
                <Route
                    path="selecionar-organizacao"
                    element={
                        <ProtectedRoutes value="selecionar-empresa">
                            <Pages.SelectCompany/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="selecionar-evento"
                    element={
                        <ProtectedRoutes value="selecionar-evento">
                            <Pages.SelectEvent/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="painel-operador"
                    element={
                        <ProtectedRoutes value="painel-operador">
                            <Pages.PainelOperador/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="painel-admin"
                    element={
                        <ProtectedRoutes value="painel-admin">
                            <Pages.PainelAdmin/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="painel-monitor"
                    element={
                        <ProtectedRoutes value="painel-monitor">
                            <Pages.PainelMonitor/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="leitor-dados"
                    element={
                        <ProtectedRoutes value="leitor-dados">
                            <Pages.LeitorDados/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="impressao-customizada"
                    element={
                        <ProtectedRoutes value="impressao-customizada">
                            <Pages.CustomPrint/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="leitor-code"
                    element={
                        <ProtectedRoutes value="leitor-code">
                            <Pages.QRScanner/>
                        </ProtectedRoutes>
                    }
                />
                <Route
                    path="validar"
                    element={
                        <ProtectedRoutes value="validar">
                            <Pages.Validar/>
                        </ProtectedRoutes>
                    }
                />
            </Routes>
        </div>
    );
}
