import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import { useState } from "react";
import styles from "./style.module.css";
import SearchInvite from "../../shared/components/searchInvite";
import RegisterInvite from "../../shared/components/registerInvite";
import InviteHistory from "../../shared/components/inviteHistory";
import Dashboard from "../../shared/components/dashboard";

export default function MonitorPanel() {
  const [showComponent, setShowComponent] = useState<string>("register");
  const [visibilidadeNav, setVisibilidadeNav] = useState<boolean>(true);

  const components: { [key: string]: JSX.Element } = {
    search: <SearchInvite />,
    register: <RegisterInvite />,
    history: <InviteHistory />,
    dashboard: <Dashboard />,
  };

  function presentComponent() {
    return components[showComponent];
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        <button
          className={styles.toggle_visibility}
          onClick={() => setVisibilidadeNav(!visibilidadeNav)}
        >
        </button>
        {visibilidadeNav && (
          <nav className={styles.nav}>
            <ul>
              <li onClick={() => setShowComponent("search")}>
                CONSULTAR INGRESSO
              </li>
              <li onClick={() => setShowComponent("register")}>
                CADASTRAR INGRESSO
              </li>
              <li onClick={() => setShowComponent("history")}>
                HISTÓRICO DE ACESSO
              </li>
              <li onClick={() => setShowComponent("dashboard")}>
                DASHBOARD
              </li>
            </ul>
          </nav>
        )}
        <div className={styles.box}>{presentComponent()}</div>
      </div>
      <Footer />
    </>
  );
}
