import { useState } from "react";
import styles from "./style.module.css";
import InviteService from "../../../services/inviteService";
import InviteHistoryReport from "../../../interfaces/inviteHistoryReport";
import { FormatDate } from "../../utils/date_util";

export default function InviteHistory() {
  const [code, setCode] = useState<string>("");
  const [inviteHistory, setInviteHistory] = useState<InviteHistoryReport>();
  const inviteService = new InviteService();

  async function searchInviteHistory(code: string) {
    try {
      const response = await inviteService.searchInviteHistory(code);
      setInviteHistory(response);
    } catch {
      const MENSAGEM_ERRO =
        "Não foi encontrado nenhum convite com codigo: " + code;
      console.log(MENSAGEM_ERRO);
      alert(MENSAGEM_ERRO);
    }
  }

  return (
    <div className={styles.container}>
      <section className={styles.box}>
        <h2>HISTÓRICO DE ACESSO</h2>
        <div className={styles.form}>
          <label>
            Código
            <span>
              <input
                type="text"
                value={code}
                maxLength={9}
                placeholder="XXXXXXXX"
                onChange={(event) => setCode(event.target.value.toUpperCase())}
              />
              <button onClick={() => searchInviteHistory(code)}>
                <span className={styles.lupa}></span>
              </button>
            </span>
          </label>
        </div>

        {inviteHistory && (
          <div className={styles.invite_info}>
            <h3>INFORMAÇÕES DO INGRESSO</h3>
            <div className={styles.invite}>
              <p>
                <span>Nome:</span> {inviteHistory.invite.guest_name}
              </p>
              <p>
                <span>Documento:</span> {inviteHistory.invite.identificator}
              </p>
              <p>
                <span>Código:</span> {inviteHistory.invite.guest_code}
              </p>
              <p>
                <span>Consumido:</span>{" "}
                {inviteHistory.invite.consumed_at !== undefined
                  ? FormatDate(inviteHistory.invite.consumed_at)
                  : "Não consumido"}
              </p>
              <p>
                <span>Setor de acesso:</span>{" "}
                {inviteHistory.invite.access_sector}
              </p>
              <p>
                <span>Válido até:</span>{" "}
                {FormatDate(inviteHistory.invite.valid_until)}
              </p>
              <p>
                <span>Email:</span> {inviteHistory.invite.guest_email}
              </p>
              <p>
                <span>Consumido por:</span> {inviteHistory.invite.validated_by}
              </p>
            </div>
          </div>
        )}

        {inviteHistory?.retries && (
          <div className={styles.attemp_list}>
            <h3>TENTATIVAS DE REUTILIZAÇÃO</h3>
            {inviteHistory?.retries.map((attempt, index) => (
              <div key={index} className={styles.attempt}>
                <span>{attempt.validated_by_retry}</span>
                <span>{attempt.access_sector_retry}</span>
                <span>{FormatDate(attempt.retry_at)}</span>
              </div>
            ))}
          </div>
        )}
      </section>
    </div>
  );
}
