import axios from "axios";
import LoginData from "../interfaces/loginData";
const URL: string = `${process.env.REACT_APP_URL_AUTH}/auth`;

export default class AuthService {
  axiosInstance = axios.create({
    baseURL: URL,
  });

  async login(user: LoginData): Promise<boolean> {
    try {
      const response = await this.axiosInstance.post(`${URL}`, user);
      if (response.data.details === "OK") {
        setLoginInfo(response.data);
        localStorage.setItem("operador", user.user);
        return true;
      }
    } catch (err) {
      console.log(err);
    }
    return false;
  }

  isUserAuthenticated() {
    return localStorage.getItem("token") !== null;
  }

  isAdmin(): boolean {
    const roles = localStorage.getItem("roles") || "";
    return roles.includes("admin");
  }

  whichIsMainRole(): string {
    const roles = localStorage.getItem("roles") || "";
    var mainRole = ""

    if(roles.includes("operator")){
      mainRole ="operator"
    }
    if(roles.includes("panel")){
      mainRole ="panel"
    }
    if(roles.includes("admin")){
      mainRole ="admin"
    }
    return mainRole
  }
  
  logout() {
    localStorage.clear();
  }
}
function setLoginInfo(data: any): void {
  localStorage.setItem("token", data.token);
  localStorage.setItem("roles", data.roles);
}
