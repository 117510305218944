import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import { useEffect, useState } from "react";
import { Html5QrcodeScanner } from "html5-qrcode";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";

const DEFAULT_CODE_LENGTH: number = 8;
const SPECIAL_CODE_LENGTH: number = 9;

export default function QRScanner() {
  const navigate = useNavigate();
  const [currentValue, setCurrentValue] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [scanner, setScanner] = useState<Html5QrcodeScanner>();
  function redirectWithProps(code: string) {
    navigate("/validar", { state: { code: code, path: "/leitor-code" } });
  }

  useEffect(() => {
    if (scanner === undefined) {
      setScanner(
        new Html5QrcodeScanner(
          "render",
          {
            qrbox: {
              width: 250,
              height: 250,
            },
            fps: 5,
            rememberLastUsedCamera: true,
            aspectRatio: 1,
          },
          false
        )
      );
    }

    if (scanner !== undefined) {
      scanner.render(success, () => {});
    }
  }, [scanner]);

  async function success(value: string) {
    if (!isValid(value) || value === currentValue) {
      setMessage("CÓDIGO INVÁLIDO");
      return;
    }
    setMessage("");
    setCurrentValue(value);
    scanner?.clear();
    redirectWithProps(value);
  }

  function isValid(code: string): boolean {
    code = code.replace(/\n/g, "");
    return (
      code.length == DEFAULT_CODE_LENGTH || code.length == SPECIAL_CODE_LENGTH
    );
  }

  function searchByCode() {
    if (currentValue.length < 1) return;
    if (isValid(currentValue)) {
      redirectWithProps(currentValue);
    }
    setMessage("CÓDIGO INVÁLIDO");
  }

  function goBack() {
    if (scanner !== undefined) {
      const state = scanner.getState();
      if (!state.toString().includes("3") && !state.toString().includes("1")) {
        scanner.pause(true);
      }
    }
    navigate("/painel-operador");
  }

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.box}>
          <h2
            className={`${
              message === "CÓDIGO INVÁLIDO"
                ? styles.codigo_invalido
                : styles.default
            }`}
          >
            {message}
          </h2>
          <div className={styles.input_box}>
            <label>
              MÍDIA
              <span>
                <input
                  type="text"
                  value={currentValue}
                  onChange={(event) =>
                    setCurrentValue(event.target.value.toUpperCase())
                  }
                  maxLength={DEFAULT_CODE_LENGTH}
                />
                <button onClick={() => searchByCode()}>
                  <span className={styles.lupa}></span>
                </button>
              </span>
            </label>
          </div>
          <div
            className={`${styles.render} ${styles.render_active}`}
            id="render"
          ></div>


        </div>
        <button className={styles.goback_btn} onClick={() => goBack()}>
          Voltar
        </button>
      </div>
      <Footer />
    </>
  );
}
