const FormatDate = (data: string) => {
  const dataObj = new Date(data);
  const dataComFuso = dataObj.toLocaleString("pt-br", {
    timeZone: "America/Sao_Paulo",
  });
  const dia = dataComFuso.slice(0, 2);
  const mes = dataComFuso.slice(3, 5);
  const ano = dataComFuso.slice(6, 10);
  const hora = dataComFuso.slice(12, 14);
  const minutos = dataComFuso.slice(15, 17);

  return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
};

export { FormatDate };
